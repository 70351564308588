
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import Role from '@/types/Role';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Role",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Attach Role",
        url: "user/role"
      },
      {
        name: "Detach Role",
        url: "user/role"
      },
    ]);
    const name = ref("User");
    const levels = reactive(["JS1", "JS2", "JS3", "SS1", "SS2", "SS3", "STF"]);
    const actions = reactive(["add", "delete"]);
    const action = ref("");
    const selected = reactive<Role[]>([]);
    const selection = reactive([]);
    const users = reactive([
      {
        id: 1,
        level: "STF",
        email: "john.doe@mail.com",
        roles: 2,
      },
      {
        id: 2,
        level: "SDT",
        email: "jack.doe@mail.com",
        roles: 0,
      },
      {
        id: 1,
        level: "SDT",
        email: "jane.doe@mail.com",
        roles: 1,
      },
    ]);
    const roles = reactive([
          {
            id: "1",
            name: "User",
            entity: "isUser",
            value: true,
            permissions: [
              { name: "create", value: true, disabled: false },
              { name: "readAll", value: true, disabled: false },
              { name: "read", value: true, disabled: false },
              { name: "update", value: true, disabled: false },
              { name: "delete", value: true, disabled: false },
            ],
            scope: "public",
            status: true,
            disabled: false,
            description: "This is user default role",
            created_at: 1619634314063,
            updated_at: 1619634314063,
          },
        ]);
    const user = reactive({
        displayName: "John Doe",
        phoneNumber: "+123456789",
        email: "john.doe@mail.com",
        entity: "tenant",
        tag: "STF",
        level: "STF",
        roles: [
          {
            id: "1",
            name: "User",
            entity: "isUser",
            value: true,
            permissions: [
              { name: "create", value: false, disabled: false },
              { name: "readAll", value: true, disabled: false },
              { name: "read", value: true, disabled: false },
              { name: "update", value: true, disabled: false },
              { name: "delete", value: false, disabled: false },
            ],
            description: "This is user default role",
          },
        ],
      });
    const nav = computed(() => store.getters.nav);
    
    return { name, nav, links, user, levels, selection, selected, users, roles, actions, action };
  },
});
